.u-text-center {
  text-align: center;
}

.u-right-text {
  text-align: right;
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem;
}

.u-margin-bottom-medium {
  margin-bottom: 3rem;
}

.u-margin-bottom-large {
  margin-bottom: 1rem;
  @include responsive(phone) {
    margin-bottom: 1em;
  }
}

.u-display-flex {
  display: flex;
  @include responsive(phone) {
    flex-direction: column;
  }
}

.u-full-width {
  width: 100%;
}

.impact {
  height: 60em;
  width: 100%;
  @include responsive(phone) {
    height: 100%;
  }
  & h2 {
    font-size: 3em;
  }

  &__flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
    @include responsive(phone) {
      flex-direction: column;
    }
  }

  &__box {
    height: 300px;
    width: 300px;
    border-radius: 20px;
    cursor: pointer;
    @include responsive(phone) {
      margin-top: 30px;
    }
    // margin-right: 50px;
  }
}

.color1 {
  border: 4px solid #dacdff;
  background-color: #dacdff;
}

.color2 {
  border: 4px solid #a8d8ff;
  background-color: #a8d8ff;
}

.color3 {
  border: 4px solid #ffeaee;
  background-color: #ffeaee;
}

.mt-3 {
  margin-top: 5em;
}

.relative {
  margin-left: 50px;
}

.relative_bottom {
  margin-left: 300px;
  margin-bottom: 50px;
}
