// Colors
$primaryColor: #97be40;
$primaryColorAccent: #c6de39;

$darkDark: #171717;
$darkMedium: #363836;
$darkLight: #797979;
$darkAccent: #f1f1f1;

$colorBlack: #000000;
$colorWhite: #ffffff;
$colorRed: #ff4136;

$defaultLinearGradient: linear-gradient(to top, #97be40, #c6de39);

// Font sizes
$defaultFontSize: 1.6rem;

// Container widths
$containerWidth: 114rem;

// Elements heights
$headerHeight: 7rem;

// Misc
$borderRadiusWidth: 0.6rem;

// Fonts
$titleFont: "Poppins", sans-serif;
$textFont: "Poppins", sans-serif;
