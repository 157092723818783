.services {
  @include responsive(tabletLandscape) {
    padding: 0 3em;
  }
  @include responsive(phone) {
    padding: 0 2em;
  }
  &__main {
    padding: 10em 0;
    @include responsive(phone) {
      padding: 5em 0;
    }
  }

  &__first--link {
    font-size: medium;
    font-family: "poppins";
    line-height: 4em;
    color: #03a84e;

    &:hover {
      // color: #0afa82;
      border-bottom: 2px solid #03a84e;
    }
  }

  &__second--link {
    font-size: medium;
    font-family: "poppins";
    line-height: 4em;
    color: #ff9d00;

    &:hover {
      // color: #0afa82;
      border-bottom: 2px solid #ff9d00;
    }
  }

  &__third--link {
    font-size: medium;
    font-family: "poppins";
    line-height: 4em;
    color: #00c0ea;

    &:hover {
      // color: #0afa82;
      border-bottom: 2px solid #00c0ea;
    }
  }

  &__text {
    text-align: center;

    & h2 {
      font-size: 5em;
      color: #03a84e;
      @include responsive(phone) {
        font-size: 3em;
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include responsive(phone) {
      padding: 0 0.5em;
    }

    & .service:nth-child(odd) {
      & .service__icon {
        animation: move-in-left 3000ms ease-in-out;
        @include responsive(phone) {
          flex: none;
          height: 230px;
          // width: 15em;
        }
      }
      .service__content {
        margin-left: 10em;
      }
    }

    & .service:nth-child(even) {
      flex-direction: row-reverse;

      @include responsive(phone) {
        flex-direction: column-reverse;
      }

      & .service__content {
        margin-right: 10em;
      }

      & .service__icon {
        animation: move-in-right 3000ms ease-in-out;
        @include responsive(phone) {
          animation: move-in-left 3000ms ease-in-out;
          height: 230px;
        }
        [data-content]:before {
          right: -1.2em;
          left: auto;

          @include responsive(tabletLandscape) {
            right: -2.5em;
          }
        }
      }
    }

    & .service {
      display: flex;
      flex-direction: row;
      margin-top: 5em;
      border-radius: 1em;
      padding: 1em;
      cursor: pointer;

      @include responsive(phone) {
        flex-direction: column-reverse;
        margin-bottom: 7em;
        min-height: 300px;
        text-align: center;
      }

      &__icon {
        flex: 35%;
        // width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 10px;
        height: 350px;

        @include responsive(phone) {
          height: 150px;
          margin-top: 7em;
          flex: none;
          background-position: center;
        }

        h1 {
          position: relative;
          span {
            display: none;
          }
        }

        [data-content]:before {
          content: attr(data-content);
          color: $darkAccent;
          position: absolute;
          font-size: 7em;
          left: -1.2em;
          top: -1em;

          @include responsive(tabletLandscape) {
            font-size: 4em;
            position: relative;
            left: -0.3em;
            top: -1.3em;
          }

          @include responsive(phone) {
            content: "";
          }
        }
      }

      &__content {
        flex: 60%;
        @include responsive(phone) {
          margin-left: 0 !important;
          margin-right: 0 !important;
        }

        .title {
          margin-bottom: 1em;
          font-size: 2.4em;
          position: relative;

          &--first {
            border-bottom: 3px solid #03a84e;
          }
          &--second {
            border-bottom: 3px solid rgb(255, 157, 0);
          }
          &--third {
            border-bottom: 3px solid rgb(0, 192, 234);
          }
        }
      }
    }
  }
}

// .first {
//   background-color: #a7cf4c;
// }

// .second {
//   background-color: #1eafe5;
// }

// .third {
//   background-color: #f17025;
// }

/* only animate if the device supports hover */
@media (hover: hover) {
  #creditcard {
    /*  set start position */
    transform: translateY(110px);
    transition: transform 0.1s ease-in-out;
    /*  set transition for mouse enter & exit */
  }

  #money {
    /*  set start position */
    transform: translateY(180px);
    transition: transform 0.1s ease-in-out;
    /*  set transition for mouse enter & exit */
  }

  button:hover #creditcard {
    transform: translateY(0px);
    transition: transform 0.2s ease-in-out;
    /*  overide transition for mouse enter */
  }

  button:hover #money {
    transform: translateY(0px);
    transition: transform 0.3s ease-in-out;
    /*  overide transition for mouse enter */
  }
}

.button {
  border: none;
  margin-top: 20px;
  outline: none;
  padding: 1rem 90px 1rem 2rem;
  position: relative;
  border-radius: 8px;
  background-color: #004a8f;
  color: #fff;
  font-size: 1.6em;
  font-family: "poppins", sans-serif;
  cursor: pointer;
  font-weight: 600;
  box-shadow: rgba(0, 9, 61, 0.2) 0px 4px 8px 0px;

  &__svg {
    position: absolute;
    overflow: visible;
    bottom: 6px;
    right: 0.2rem;
    height: 140%;
  }

  &:active {
    transform: translateY(1px);
  }
}

.social-links {
  transition: 0.2s ease-in-out;
  position: relative;
  list-style: none;
  display: flex;
}

.social-links:hover > li {
  transform: scale(0.95);
  filter: blur(2px);
}

.social-links li {
  transition: 0.2s ease-in-out;
  border-radius: 10px;
  place-items: center;
  cursor: pointer;
  display: grid;
  margin: auto;
  height: 3em;
  width: 3em;
}

.social-links li:hover {
  filter: blur(0px);
  transform: scale(1);
}

.social-links svg {
  transition: 0.2s ease-in-out;
  pointer-events: none;
  height: 2em;
  width: 2em;
}
