.big-hero {
  height: 70em;
  width: 100%;
  display: flex;
  background-image: url(../../assets/AnimatedShape.svg);
  background-repeat: no-repeat;
  background-position: center;

  // @include responsive(tabletPortrait) {
  //   background-image: none;
  //   height: auto;
  // }

  &__flex {
    display: flex;
    flex-direction: row;
    // padding-top: $headerHeight;
    @include responsive(tabletLandscape) {
      padding-left: 3em;
      padding-right: 3em;
    }
    @include responsive(tabletPortrait) {
      flex-direction: column;
    }
    @include responsive(phone) {
      padding: 2em 2em 0;
    }
  }

  &__left {
    flex: 25%;
    display: flex;

    align-items: center;
    @include responsive(tabletPortrait) {
      flex: 100%;
    }
  }
  &__right {
    flex: 50%;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    @include responsive(tabletPortrait) {
      flex: 100%;
      justify-content: center;
    }
    @include responsive(phone) {
      flex: 100%;

      justify-content: center;
    }
  }

  &__text {
    width: 100%;

    @include responsive(tabletPortrait) {
      text-align: center;
      padding-left: 2em;
      padding-top: 60px;
      padding-right: 2em;
    }
    @include responsive(phone) {
      padding: 0;
    }
    & h1 {
      font-family: $titleFont;
      font-size: 5em;
      line-height: 68px;
      color: #003566;

      @include responsive(phone) {
        font-size: 2.8em;
      }
    }
    & p {
      font-family: $textFont;
      font-size: 1.6em;
      color: $darkLight;
    }
  }

  &__photo {
    // height: 50em;
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
    // width: 100%;
    @include responsive(tabletPortrait) {
      height: 45em;
      margin-top: 3em;
    }
    @include responsive(phone) {
      height: 40em;
      width: 100%;
      margin-top: 2em;
    }
    & img,
    & svg {
      width: 28em;
      height: 28em;
      border-radius: 10px;
      border: 6px solid #fff;
      object-fit: cover;
      @include responsive(phone) {
        width: 100%;
        height: 20em;
      }
    }
  }
}
